//---- Fullcalendar ----//
//import 'fullcalendar';
//import 'fullcalendar/dist/fullcalendar.css';

//---- FontAwewome ----//
import 'font-awesome/css/font-awesome.min.css';

//---- Bootsrap ----//
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

//---- Select 2 ----//
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.full.min.js';
import 'select2/dist/js/i18n/fr';
import 'select2-bootstrap-theme/dist/select2-bootstrap.min.css';

//---- Slick ----//
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//---- Jquery-ui ----//
import 'jquery-ui-bundle/jquery-ui';
import 'jquery-ui-bundle/jquery-ui.css';

//---- Flag-Icon ----//
import "flag-icon-css/css/flag-icon.min.css";

//---- Cropper ----//
import "cropper/dist/cropper.css";
import "cropper/dist/cropper.js";

//---- Decimal ----//
// import "decimal.js/decimal.min.js";

//--- Hamburger ---//
import "hamburgers/dist/hamburgers.css";
